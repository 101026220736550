import React from 'react'
import './Hero.css'
import arrow_icon from '../Assets/arrow.png'


const Hero = () => {
  return (
    <div className='hero'>
        <div className="hero-left">
            <h2>Nuevos Productos</h2>
            <div>
              <p>Colecciones</p>
              <p>Para todos</p>
            </div>
            <div className="hero-latest-btn">
              <div>Últimas Colecciones</div>
              <img src={arrow_icon} alt="" />
            </div>
        </div>
        <div className="hero-right">

        </div>
    </div>
  )
}
export default Hero