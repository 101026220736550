import React from 'react';
import './CSS/Companies.css';
import { Link } from 'react-router-dom';

function Companies() {
  const companyNames = ['Administrativo', 'Productivo', 'Hospitalario', 'Limpieza'];
  const directoryPath = 'https://creacionesperla.com/Assets/Empresas/';
  const pdfFiles = {
    Español: 'ES_catalog_2022.pdf',
    Inglés: 'EN_catalog_2022.pdf',
  };


  // Función para manejar errores de carga y probar distintos formatos de imagen
  const handleImageError = (e) => {
    const imageSrc = e.target.src; // Obtener el src actual de la imagen
    const imageName = imageSrc.substring(imageSrc.lastIndexOf('/') + 1, imageSrc.lastIndexOf('.')); // Extraer el nombre de la imagen

    if (e.target.src.endsWith('.webp')) {
        // Si falla la carga de la imagen .webp, intenta con .jpg
         e.target.src = `${directoryPath}${imageName}.jpg`;
    } else if (e.target.src.endsWith('.jpg')) {
        // Si falla la carga de la imagen .jpg, intenta con .png
        e.target.src = `${directoryPath}${imageName}.png`;
    }
  };

  return (
    <div className="companies-container">
      <div className="buttons-container">
        {Object.keys(pdfFiles).map((name, index) => (
          <button 
            key={index}
            onClick={() => window.open(`${directoryPath}${pdfFiles[name]}`, '_blank')}
            className="catalog-button"
          >
            Ver Catálogo: {name}
          </button>
        ))}
      </div>
      <div className="cards-container">
        {companyNames.map((name, index) => (
          <div key={index} className="company-card">
            <Link to={`/empresas/${name}`} onClick={() => window.scrollTo(0, 0)}>
            <img src={`${directoryPath}${name}.webp`} 
              alt={name}
              loading="lazy"
              onError={handleImageError}
              className="company-image" 
            />
            <h3>{name}</h3>
            </Link>
          </div>
        ))}
        <p>Images designed and distributed by Freepik</p>
      </div>
    </div>
  );
}

export default Companies;


